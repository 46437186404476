import { h } from "vue";
import { RouteRecordRaw, RouterView } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    // Users
    {
        path: "/rights-management/users",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "Users",
                component: () => import("@/views/rights-management/users/Users.vue"),
            },
            {
                path: "create",
                name: "UsersCreate",
                component: () => import("@/views/rights-management/users/components/User.vue"),
            },
            {
                path: ":id/edit",
                name: "UsersEdit",
                component: () => import("@/views/rights-management/users/components/User.vue"),
                props: true,
            },
        ],
    },

    // Roles
    {
        path: "/rights-management/roles",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "Roles",
                component: () => import("@/views/rights-management/roles/Roles.vue"),
            },
            {
                path: "create",
                name: "RolesCreate",
                component: () => import("@/views/rights-management/roles/components/Role.vue"),
            },
            {
                path: ":id/edit",
                name: "RolesEdit",
                component: () => import("@/views/rights-management/roles/components/Role.vue"),
                props: true,
            },
        ],
    },

    // Permissions
    {
        path: "/rights-management/permissions",
        name: "Permissions",
        component: () => import("@/views/rights-management/permissions/Permissions.vue"),
    },
];

export default routes;
