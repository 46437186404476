import { createRouter, createWebHistory, RouteRecordRaw, RouterView } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

/*
 * CONCRETE ROUTER INCLUDES FOR DIFFERENT SECTIONS
 */
import administrationRoutes from "./administration";
import masterdataRoutes from "./masterdata";
import productionRoutes from "./production";
import rightsManagementRoutes from "./rights-management";
import authenticationRoutes from "./authentication";
import settingsRoutes from "./settings";
/*
 * -- ROUTER INCLUDES
 */

// Must be the last one!
const notFoundRoute = {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
};

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        redirect: "/dashboard",
        component: () => import("@/layout/Layout.vue"),
        children: [
            {
                path: "/dashboard",
                name: "Dashboard",
                component: () => import("@/views/Dashboard.vue"),
            },
            {
                path: "/recipes",
                name: "Recipes",
                component: () => import("@/views/production/recipes/Recipes.vue"),
            },
        ],
    },
];

// Add all child routes to parent
const allChildRoutes = administrationRoutes.concat(masterdataRoutes).concat(productionRoutes).concat(rightsManagementRoutes).concat(settingsRoutes);
routes[0].children = routes[0].children?.concat(allChildRoutes);

// Add global authentication routes with custom layout
const allRoutes: Array<RouteRecordRaw> = routes.concat(authenticationRoutes);
allRoutes.push(notFoundRoute);

const router = createRouter({
    history: createWebHistory(),
    routes: allRoutes,
});

router.beforeEach(() => {
    // reset config to initial state
    store.commit(Mutations.RESET_LAYOUT_CONFIG);

    store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

export default router;
