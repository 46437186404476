import { h } from "vue";
import { RouteRecordRaw, RouterView } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    // Equipments
    {
        path: "/production/equipments",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "Equipments",
                component: () => import("@/views/production/equipments/Equipments.vue"),
            },
            {
                path: "create",
                name: "EquipmentCreate",
                component: () => import("@/views/production/equipments/components/Equipment.vue"),
            },
            {
                path: ":id/edit",
                name: "EquipmentEdit",
                component: () => import("@/views/production/equipments/components/Equipment.vue"),
                props: true,
            },
        ],
    },

    // Articles
    {
        path: "/production/articles",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "Articles",
                component: () => import("@/views/production/articles/Articles.vue"),
            },
            {
                path: "create/:tab?",
                name: "ArticleCreate",
                component: () => import("@/views/production/articles/components/Article.vue"),
            },
            {
                path: ":id/edit/:tab?",
                name: "ArticleEdit",
                component: () => import("@/views/production/articles/components/Article.vue"),
                props: true,
            },
            {
                path: ":articleId/suppliers/create",
                name: "ArticleSupplierCreate",
                component: () => import("@/views/production/articles/components/Supplier.vue"),
                props: true,
            },
            {
                path: ":articleId/suppliers/:id/edit",
                name: "ArticleSupplierEdit",
                component: () => import("@/views/production/articles/components/Supplier.vue"),
                props: true,
            },
        ],
    },

    //Recipes
    {
        path: "/production/recipes",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "Recipes",
                component: () => import("@/views/production/recipes/Recipes.vue"),
            },
            {
                path: "create/:tab?",
                name: "RecipeCreate",
                component: () => import("@/views/production/recipes/components/Recipe.vue"),
            },
            {
                path: ":id/edit/:tab?",
                name: "RecipeEdit",
                component: () => import("@/views/production/recipes/components/Recipe.vue"),
                props: true,
            },
            {
                path: ":recipeId/edit/create-serving-size",
                name: "RecipeServingCreate",
                component: () => import("@/views/production/recipes/components/ServingSize.vue"),
                props: true,
            },
            {
                path: ":recipeId/edit/edit-serving-size/:servingId",
                name: "RecipeServingEdit",
                component: () => import("@/views/production/recipes/components/ServingSize.vue"),
                props: true,
            },
        ],
    },

    // Staffs
    {
        path: "/production/staffs",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "Staffs",
                component: () => import("@/views/production/staffs/Staffs.vue"),
            },
            {
                path: "create",
                name: "StaffCreate",
                component: () => import("@/views/production/staffs/components/Staff.vue"),
            },
            {
                path: ":id/edit",
                name: "StaffEdit",
                component: () => import("@/views/production/staffs/components/Staff.vue"),
                props: true,
            },
        ],
    },

    // ProductionList
    {
        path: "/production/production-list",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: ":tab?",
                name: "ProductionList",
                component: () => import("@/views/production/productionlist/ProductionList.vue"),
                props: true,
            },
        ],
    },

    // OrderList
    {
        path: "/production/order-list",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: ":tab?",
                name: "OrderList",
                component: () => import("@/views/production/orderlist/OrderList.vue"),
                props: true,
            },
            {
                path: ":id/view",
                name: "OrderListDetail",
                component: () => import("@/views/production/orderlist/tabs/OrderList.vue"),
                props: true,
            },
        ],
    },

    // PackingList
    {
        path: "/production/packing-list",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: ":tab?",
                name: "PackingList",
                component: () =>
                    import("@/views/production/packinglist/PackingList.vue"),
                props: true,
            },
        ],
    },

];

export default routes;
