import { h } from "vue";
import { RouteRecordRaw, RouterView } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    // Additives
    {
        path: "/masterdata/additives",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "additives",
                component: () => import("@/views/masterdata/additives/Additives.vue"),
            },
            {
                path: "create",
                name: "additivescreate",
                component: () => import("@/views/masterdata/additives/components/Additive.vue"),
            },
            {
                path: ":id/edit",
                name: "additivesedit",
                component: () => import("@/views/masterdata/additives/components/Additive.vue"),
                props: true,
            },
        ],
    },

    // Allergens
    {
        path: "/masterdata/allergens",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "allergens",
                component: () => import("@/views/masterdata/allergens/Allergens.vue"),
            },
            {
                path: "create",
                name: "allergenscreate",
                component: () => import("@/views/masterdata/allergens/components/Allergen.vue"),
            },
            {
                path: ":id/edit",
                name: "allergensedit",
                component: () => import("@/views/masterdata/allergens/components/Allergen.vue"),
                props: true,
            },
        ],
    },
    // Customercategories
    {
        path: "/masterdata/customer-categories",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "customercategories",
                component: () => import("@/views/masterdata/customercategories/CustomerCategories.vue"),
            },
            {
                path: "create",
                name: "customercategoriescreate",
                component: () => import("@/views/masterdata/customercategories/components/CustomerCategory.vue"),
            },
            {
                path: ":id/edit",
                name: "customercategoriesedit",
                component: () => import("@/views/masterdata/customercategories/components/CustomerCategory.vue"),
                props: true,
            },
        ],
    },

    // Exportformats
    {
        path: "/masterdata/export-formats",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "exportformats",
                component: () => import("@/views/masterdata/exportformats/Exportformats.vue"),
            },
            {
                path: "create",
                name: "exportformatscreate",
                component: () => import("@/views/masterdata/exportformats/components/ExportFormat.vue"),
            },
            {
                path: ":id/edit",
                name: "exportformatsedit",
                component: () => import("@/views/masterdata/exportformats/components/ExportFormat.vue"),
                props: true,
            },
        ],
    },

    // Paymentmethods
    {
        path: "/masterdata/paymentmethods",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "paymentmethods",
                component: () => import("@/views/masterdata/paymentmethods/Paymentmethods.vue"),
            },
            {
                path: "create",
                name: "paymentmethodscreate",
                component: () => import("@/views/masterdata/paymentmethods/components/PaymentMethod.vue"),
            },
            {
                path: ":id/edit",
                name: "paymentmethodsedit",
                component: () => import("@/views/masterdata/paymentmethods/components/PaymentMethod.vue"),
                props: true,
            },
        ],
    },

    // Sales Taxes
    {
        path: "/masterdata/sales-taxes",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "SalesTaxes",
                component: () => import("@/views/masterdata/sales-taxes/SalesTaxes.vue"),
            },
            {
                path: "create",
                name: "SalesTaxesCreate",
                component: () => import("@/views/masterdata/sales-taxes/components/SalesTax.vue"),
            },
            {
                path: ":id/edit",
                name: "SalesTaxesEdit",
                component: () => import("@/views/masterdata/sales-taxes/components/SalesTax.vue"),
                props: true,
            },
        ],
    },

    // Storages
    {
        path: "/masterdata/storages",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "Storages",
                component: () => import("@/views/masterdata/storages/Storages.vue"),
            },
            {
                path: "create",
                name: "StoragesCreate",
                component: () => import("@/views/masterdata/storages/components/Storage.vue"),
            },
            {
                path: ":id/edit",
                name: "StoragesEdit",
                component: () => import("@/views/masterdata/storages/components/Storage.vue"),
                props: true,
            },
        ],
    },

    // Units
    {
        path: "/masterdata/units",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "Units",
                component: () => import("@/views/masterdata/units/Units.vue"),
            },
            {
                path: "create",
                name: "UnitCreate",
                component: () => import("@/views/masterdata/units/components/Unit.vue"),
            },
            {
                path: ":id/edit",
                name: "UnitEdit",
                component: () => import("@/views/masterdata/units/components/Unit.vue"),
                props: true,
            },
        ],
    },

    // Equipment Groups
    {
        path: "/masterdata/equipment-groups",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "EquipmentGroups",
                component: () => import("@/views/masterdata/equipment-groups/EquipmentGroups.vue"),
            },
            {
                path: "create",
                name: "EquipmentGroupCreate",
                component: () => import("@/views/masterdata/equipment-groups/components/EquipmentGroup.vue"),
            },
            {
                path: ":id/edit",
                name: "EquipmentGroupEdit",
                component: () => import("@/views/masterdata/equipment-groups/components/EquipmentGroup.vue"),
                props: true,
            },
        ],
    },

    // Staff Categories
    {
        path: "/masterdata/staff-categories",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "StaffCategories",
                component: () => import("@/views/masterdata/staff-categories/StaffCategories.vue"),
            },
            {
                path: "create",
                name: "StaffCategoriesCreate",
                component: () => import("@/views/masterdata/staff-categories/components/StaffCategory.vue"),
            },
            {
                path: ":id/edit",
                name: "StaffCategoriesEdit",
                component: () => import("@/views/masterdata/staff-categories/components/StaffCategory.vue"),
                props: true,
            },
        ],
    },

    // Production Locations
    {
        path: "/masterdata/production-locations",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "ProductionLocations",
                component: () => import("@/views/masterdata/production-locations/ProductionLocations.vue"),
            },
            {
                path: "create",
                name: "ProductionLocationCreate",
                component: () => import("@/views/masterdata/production-locations/components/ProductionLocation.vue"),
            },
            {
                path: ":id/edit",
                name: "ProductionLocationEdit",
                component: () => import("@/views/masterdata/production-locations/components/ProductionLocation.vue"),
                props: true,
            },
        ],
    },

    // Posting Accounts
    {
        path: "/masterdata/posting-accounts",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "PostingAccounts",
                component: () => import("@/views/masterdata/posting-accounts/PostingAccounts.vue"),
            },
            {
                path: "create",
                name: "PostingAccountsCreate",
                component: () => import("@/views/masterdata/posting-accounts/components/PostingAccount.vue"),
            },
            {
                path: ":id/edit",
                name: "PostingAccountsEdit",
                component: () => import("@/views/masterdata/posting-accounts/components/PostingAccount.vue"),
                props: true,
            },
        ],
    },

    // Recipe Groups
    {
        path: "/masterdata/recipe-groups",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "RecipeGroups",
                component: () => import("@/views/masterdata/recipe-groups/RecipeGroups.vue"),
            },
            {
                path: "create",
                name: "RecipeGroupCreate",
                component: () => import("@/views/masterdata/recipe-groups/components/RecipeGroup.vue"),
            },
            {
                path: ":id/edit",
                name: "RecipeGroupEdit",
                component: () => import("@/views/masterdata/recipe-groups/components/RecipeGroup.vue"),
                props: true,
            },
        ],
    },

    // Event Locations
    {
        path: "/masterdata/event-locations",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "EventLocations",
                component: () => import("@/views/masterdata/event-locations/EventLocations.vue"),
            },
            {
                path: "create",
                name: "EventLocationCreate",
                component: () => import("@/views/masterdata/event-locations/components/EventLocation.vue"),
            },
            {
                path: ":id/edit",
                name: "EventLocationEdit",
                component: () => import("@/views/masterdata/event-locations/components/EventLocation.vue"),
                props: true,
            },
        ],
    },

    // Article Group
    {
        path: "/masterdata/article-groups",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "ArticleGroups",
                component: () => import("@/views/masterdata/article-groups/ArticleGroups.vue"),
            },
            {
                path: "create",
                name: "ArticleGroupsCreate",
                component: () => import("@/views/masterdata/article-groups/components/ArticleGroup.vue"),
            },
            {
                path: ":id/edit",
                name: "ArticleGroupsEdit",
                component: () => import("@/views/masterdata/article-groups/components/ArticleGroup.vue"),
                props: true,
            },
        ],
    },

    // Suppliercategories
    {
        path: "/masterdata/suppliercategories",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "suppliercategories",
                component: () => import("@/views/masterdata/suppliercategories/Suppliercategories.vue"),
            },
            {
                path: "create",
                name: "suppliercategoriescreate",
                component: () => import("@/views/masterdata/suppliercategories/components/Create.vue"),
            },
            {
                path: ":id/edit",
                name: "suppliercategoriesedit",
                component: () => import("@/views/masterdata/suppliercategories/components/Create.vue"),
                props: true,
            },
        ],
    },

    // Print Templates
    {
        path: "/masterdata/print-templates",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "PrintTemplates",
                component: () => import("@/views/masterdata/print-templates/PrintTemplates.vue"),
            },
            {
                path: "create",
                name: "PrintTemplateCreate",
                component: () => import("@/views/masterdata/print-templates/components/PrintTemplate.vue"),
            },
            {
                path: ":id/edit",
                name: "PrintTemplateEdit",
                component: () => import("@/views/masterdata/print-templates/components/PrintTemplate.vue"),
                props: true,
            },
        ],
    },

    // Mail Texts
    {
        path: "/masterdata/mail-texts",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "MailTexts",
                component: () => import("@/views/masterdata/mail-texts/MailTexts.vue"),
            },
            {
                path: "create",
                name: "MailTextCreate",
                component: () => import("@/views/masterdata/mail-texts/components/MailText.vue"),
            },
            {
                path: ":id/edit",
                name: "MailTextEdit",
                component: () => import("@/views/masterdata/mail-texts/components/MailText.vue"),
                props: true,
            },
        ],
    },

    // Cash Books
    {
        path: "/masterdata/cash-books",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "CashBooks",
                component: () => import("@/views/masterdata/cash-books/CashBooks.vue"),
            },
            {
                path: "create",
                name: "CashBooksCreate",
                component: () => import("@/views/masterdata/cash-books/components/CashBook.vue"),
            },
            {
                path: ":id/edit",
                name: "CashBooksEdit",
                component: () => import("@/views/masterdata/cash-books/components/CashBook.vue"),
                props: true,
            },
        ],
    },

    // Salutations
    {
        path: "/masterdata/salutations",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "Salutations",
                component: () => import("@/views/masterdata/salutations/Salutations.vue"),
            },
            {
                path: "create",
                name: "SalutationsCreate",
                component: () => import("@/views/masterdata/salutations/components/Salutation.vue"),
            },
            {
                path: ":id/edit",
                name: "SalutationsEdit",
                component: () => import("@/views/masterdata/salutations/components/Salutation.vue"),
                props: true,
            },
        ],
    },

    // Labelprintformats
    {
        path: "/masterdata/labelprintformats",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "labelprintformats",
                component: () => import("@/views/masterdata/labelprintformats/Labelprintformats.vue"),
            },
            {
                path: "create",
                name: "labelprintformatcreate",
                component: () => import("@/views/masterdata/labelprintformats/components/Labelprintformat.vue"),
            },
            {
                path: ":id/edit",
                name: "labelprintformatedit",
                component: () => import("@/views/masterdata/labelprintformats/components/Labelprintformat.vue"),
                props: true,
            },
        ],
    },

    {
        path: "/masterdata/textbricks",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "Textbricks",
                component: () => import("@/views/masterdata/textbricks/Textbricks.vue"),
            },
            {
                path: "create",
                name: "textbrickcreate",
                component: () => import("@/views/masterdata/textbricks/components/Textbrick.vue"),
            },
            {
                path: ":id/edit",
                name: "textbrickedit",
                component: () => import("@/views/masterdata/textbricks/components/Textbrick.vue"),
                props: true,
            },
        ],
    },

    {
        path: "/masterdata/images",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "Images",
                component: () => import("@/views/masterdata/images/Images.vue"),
            },
            {
                path: "create",
                name: "imagecreate",
                component: () => import("@/views/masterdata/images/components/Image.vue"),
            },
            {
                path: ":id/edit",
                name: "imageedit",
                component: () => import("@/views/masterdata/images/components/Image.vue"),
                props: true,
            },
        ],
    },

];

export default routes;
