import { h } from "vue";
import { RouteRecordRaw, RouterView } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/settings/:tab?",
        name: "Settings",
        props: true,
        component: () => import("@/views/settings/Settings.vue"),
    },
    {
        path: "/settings",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: ":tab?",
                name: "Settings",
                props: true,
                component: () => import("@/views/settings/Settings.vue"),
            },
            {
                path: "cost/create",
                name: "CostCreate",
                component: () => import("@/views/settings/Costs/Components/Cost.vue"),
            },
            {
                path: ":id/edit",
                name: "CostEdit",
                component: () => import("@/views/settings/Costs/Components/Cost.vue"),
                props: true,
            },
        ],
    },
];

export default routes;
