import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { IRole, IUser } from "@/interfaces/rights-management";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface UserAuthInfo {
    errors: unknown;
    user: IUser;
    isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
    errors = {};
    user = { roles: [] as IRole[] } as IUser;
    isAuthenticated = !!JwtService.getToken();

    /**
     * Get current user object
     * @returns User
     */
    get currentUser(): IUser {
        return this.user;
    }

    /**
     * Verify user authentication
     * @returns boolean
     */
    get isUserAuthenticated(): boolean {
        return this.isAuthenticated;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_AUTH](user) {
        this.isAuthenticated = true;
        this.user = user;
        this.errors = {};
        JwtService.saveToken(user.api_token);
    }

    @Mutation
    [Mutations.SET_USER](user) {
        this.user = user;
    }

    @Mutation
    [Mutations.SET_PASSWORD](password) {
        this.user.password = password;
    }

    @Mutation
    [Mutations.PURGE_AUTH]() {
        this.isAuthenticated = false;
        this.user = { roles: [] as IRole[] } as IUser;
        this.errors = {};
        JwtService.destroyToken();
        JwtService.destroyRole();
    }

    @Action
    [Actions.LOGOUT]() {
        if (this.isAuthenticated) {
            ApiService.post("/logout", null);
        }
        this.context.commit(Mutations.PURGE_AUTH);
    }

    @Action
    [Actions.FORGOT_PASSWORD](payload) {
        return ApiService.post("forgot_password", payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.VERIFY_AUTH](payload) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post("verify_token", payload, {
                params: { hideLoading: true },
            } as AxiosRequestConfig)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AUTH, data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    this.context.commit(Mutations.PURGE_AUTH);
                });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }
}
