import { h } from "vue";
import { RouteRecordRaw, RouterView } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    //Orders
    {
        path: "/administration/orders",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "Orders",
                component: () => import("@/views/administration/orders/Orders.vue"),
            },
            {
                path: "create/:customerId?/:tab?",
                name: "OrderCreate",
                component: () => import("@/views/administration/orders/Order.vue"),
                props: true,
            },
            {
                path: ":id/edit/:tab?",
                name: "OrderEdit",
                component: () => import("@/views/administration/orders/Order.vue"),
                props: true,
            },
        ],
    },

    // Customers
    {
        path: "/administration/customers",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "Customers",
                component: () => import("@/views/administration/customers/Customers.vue"),
            },
            {
                path: "create/:tab?",
                name: "CustomerCreate",
                component: () => import("@/views/administration/customers/components/Customer.vue"),
            },
            {
                path: ":id/edit/:tab?",
                name: "CustomerEdit",
                component: () => import("@/views/administration/customers/components/Customer.vue"),
                props: true,
            },
            {
                path: ":customerId/contacts/create",
                name: "CustomerContactCreate",
                component: () => import("@/views/administration/customers/components/ContactPerson.vue"),
                props: true,
            },
            {
                path: ":customerId/contacts/:id/edit",
                name: "CustomerContactEdit",
                component: () => import("@/views/administration/customers/components/ContactPerson.vue"),
                props: true,
            },
        ],
    },

    //Appointments
    {
        path: "/administration/appointments",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "Appointments",
                component: () => import("@/views/administration/appointments/Appointments.vue"),
            },
            {
                path: "create",
                name: "AppointmentCreate",
                component: () => import("@/views/administration/appointments/components/Appointment.vue"),
            },
            {
                path: ":id/edit",
                name: "AppointmentEdit",
                component: () => import("@/views/administration/appointments/components/Appointment.vue"),
                props: true,
            },
        ],
    },

    // Suppliers
    {
        path: "/administration/suppliers",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "Suppliers",
                component: () => import("@/views/administration/suppliers/Suppliers.vue"),
            },
            {
                path: "create/:tab?",
                name: "SupplierCreate",
                component: () => import("@/views/administration/suppliers/components/Supplier.vue"),
            },
            {
                path: ":id/edit/:tab?",
                name: "SupplierEdit",
                component: () => import("@/views/administration/suppliers/components/Supplier.vue"),
                props: true,
            },
            {
                path: ":supplierId/contacts/create",
                name: "SupplierContactCreate",
                component: () => import("@/views/administration/suppliers/components/Contact.vue"),
                props: true,
            },
            {
                path: ":supplierId/contacts/:id/edit",
                name: "SupplierContactEdit",
                component: () => import("@/views/administration/suppliers/components/Contact.vue"),
                props: true,
            },
        ],
    },

    // Cashbook Entries
    {
        path: "/administration/cashbook-entries",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "CashbookEntries",
                component: () => import("@/views/administration/cashbook-entries/CashbookEntries.vue"),
            },
            {
                path: ":cashBookId/create",
                name: "CashbookEntryCreate",
                component: () => import("@/views/administration/cashbook-entries/components/CashbookEntry.vue"),
                props: true,
            },
            {
                path: ":cashBookEntryId/edit",
                name: "CashbookEntryEdit",
                component: () => import("@/views/administration/cashbook-entries/components/CashbookEntry.vue"),
                props: true,
                meta : {
                    is : 'edit'
                }
            },
            {
                path: ":cashBookEntryId/view",
                name: "CashbookEntryView",
                component: () => import("@/views/administration/cashbook-entries/components/CashbookEntry.vue"),
                props: true,
                meta : {
                    is : 'view'
                }
            }
        ],
    },

    // Invoices
    {
        path: "/administration/invoices",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "Invoices",
                component: () => import("@/views/administration/invoices/Invoices.vue"),
            },
            {
                path: ":invoiceId/book",
                name: "InvoiceBook",
                component: () => import("@/views/administration/invoices/Book.vue"),
                props: true,
            },
            {
                path: ":invoiceId/credit",
                name: "InvoiceCredit",
                component: () => import("@/views/administration/invoices/Credit.vue"),
                props: true,
            },
        ],
    },

    //Exports
    {
        path: "/administration/exports",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "Export",
                component: () => import("@/views/administration/exports/Export.vue"),
            },
        ],
    },

    //Turnovers
    {
        path: "/administration/turnover",
        component: { render: () => h(RouterView) },
        children: [
            {
                path: "",
                name: "Turnover",
                component: () => import("@/views/administration/turnovers/Turnover.vue"),
            },
        ],
    },
];

export default routes;
